export enum ConversationMessageStatusEnum {
  Created = 'created',
  Dispatched = 'dispatched',
  Sent = 'sent',
  Delivered = 'delivered',
  Read = 'read',
  Error = 'error',
  Failed = 'failed',
  Received = 'received',
  Deleted = 'deleted',
  Temporary = 'temporary',
  TemporaryInError = 'temporaryInError',
  NoFundsError = 'noFundsError',
  Held = 'held',
}
